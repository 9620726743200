/* eslint-disable */

import React, { useEffect } from "react";
import { useTranslation, useI18next, Link } from "gatsby-plugin-react-i18next";
import Modal from "react-modal";

import HeaderLogoImage from "../images/common/header_logo@2x.png";

Modal.setAppElement("#___gatsby");

function LinkSwitchLanguage(props) {
  const { i18n, changeLanguage } = useI18next();

  const changeLanguageAndCloseModal = (props) => {
    changeLanguage(props.localeCode);
    props.closeModal();
  };

  return (
    <span>
      <a
        id={`${props.localeCode}_lang_menu`}
        className={i18n.language === props.localeCode ? "lang_selected" : ""}
        onClick={() => changeLanguageAndCloseModal(props)}
        style={{ cursor: "pointer" }}
      >
        {props.localeText}
      </a>
    </span>
  );
}

export default function MenuModal(props) {
  const { t } = useTranslation();

  useEffect(() => {
    const $menuModal = document.querySelector("#menu-modal");

    if ($menuModal) {
      if (props.isOpen === true) {
        $menuModal.classList.add("active");
      } else {
        $menuModal.classList.remove("active");
      }
    }
  }, [props.isOpen]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={{
        overlay: {
          display: props.isOpen ? "block" : "none",
          backgroundColor: "rgba(170,170,170,0.3)",
        },
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: "unset",
          padding: 0,
          overflow: "unset",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
      contentLabel="Menu Modal"
      id="menuModal"
    >
      <div className="header_img"></div>
      <div className="header_full_box">
        <div className="header_box">
          <div className="header">
            <div className="logo">
              <Link to="/mmind" className="moveToTopLogo">
                <img src={HeaderLogoImage} alt="Header logo@2x" />
              </Link>
            </div>
            <div className="menu">
              <a onClick={props.onRequestClose}>
                <div className="inner">
                  <ul id="menu-modal" className="menu-trigger">
                    <li>
                      <span></span>
                      <span></span>
                      <span></span>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="lang_column">
              <LinkSwitchLanguage
                localeCode="en"
                localeText="English"
                closeModal={props.onRequestClose}
              />
              <span>&nbsp;/&nbsp;</span>
              <LinkSwitchLanguage
                localeCode="ja"
                localeText="日本語"
                closeModal={props.onRequestClose}
              />
              <span>&nbsp;/&nbsp;</span>
              <LinkSwitchLanguage
                localeCode="zh"
                localeText="中文(简体)"
                closeModal={props.onRequestClose}
              />
            </div>
            <Link
              className="menu_item"
              to="/mmind"
              onClick={props.onRequestClose}
            >
              {t("mm_top_link")}
            </Link>
            <Link
              className="menu_item"
              to="/mmind/fthinking/"
              onClick={props.onRequestClose}
            >
              {t("mm_five_type_link")}
            </Link>
            <Link
              className="menu_item"
              to="/mmind/asite/"
              onClick={props.onRequestClose}
            >
              {t("mm_about_site")}
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}
